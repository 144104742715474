// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }

  show_details(){
    window.$('#job-application-modal').modal('show');
  }

  // send_request()
  // {
  //   var xhr = new XMLHttpRequest();
  //   xhr.open("GET", "/sr/job_applications/2/fetch_details", true);
  //   xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest")
  //   xhr.onload = function (e) {
  //     if (xhr.readyState === 4) {
  //       if (xhr.status === 200) {
  //         eval(xhr.responseText);
  //       } else {
  //         console.error(xhr.statusText);
  //       }
  //     }
  //   };
  //   xhr.onerror = function (e) {
  //     console.error(xhr.statusText);
  //   };
  //   xhr.send(null);
  // }
}



